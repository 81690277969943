<template>
  <div class="attachments">
    <Header back="返回" title="财务列表" index="首页" titleOne="财务管理" titleTwo="财务列表" beforeTitle="编辑" />
    <div class="content">
      <h1>财务信息</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        label-width="80px"
        :label-position="labelPosition"
      >
        <el-form-item label="企业名称">
          <el-input v-model="form.company_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="合同名称">
          <el-input v-model="form.contract_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-input v-model="form.product_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="签单人">
          <el-input v-model="form.signatory_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="签单时间">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.down_time"
            style="width: 100%"
            disabled
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="应收款（元）" prop="receivables">
          <el-input v-model="form.receivables"></el-input>
        </el-form-item>
        <el-form-item label="已收款（元）" prop="received">
          <el-input v-model="form.received"></el-input>
        </el-form-item>
        <el-form-item label="待收款（元）" prop="uncollected">
          <el-input v-model="form.uncollected"></el-input>
        </el-form-item>
        <el-form-item label="核销">
          <el-select v-model="form.check" placeholder="请选择">
            <el-option label="未核销" :value="1">未核销</el-option>
            <el-option label="核销" :value="2">核销</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退款">
          <el-select v-model="form.refund" placeholder="请选择类型">
            <el-option label="-" :value="1">-</el-option>
            <el-option label="已拒绝" :value="2">已拒绝</el-option>
            <el-option label="退款中" :value="3">退款中</el-option>
            <el-option label="已同意" :value="4">已同意</el-option>
            <el-option label="已退款" :value="5">已退款</el-option>
          </el-select>
        </el-form-item>
        <h1>收款模式</h1>
        <el-form-item label="收费方式">
          <el-select v-model="form.billing_method" placeholder="请选择" disabled>
            <el-option label="固定" :value="1">固定</el-option>
            <el-option label="计提" :value="2">计提</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="总金额（元）">
          <el-input v-model="form.total_money" disabled></el-input>
        </el-form-item>
        <el-form-item label="定金（元）">
          <el-input v-model="form.deposit" disabled></el-input>
        </el-form-item>
        <el-form-item label="提交（元）">
          <el-input v-model="form.submit_money" disabled></el-input>
        </el-form-item>
        <el-form-item label="通过（元）">
          <el-input v-model="form.pass_money" disabled></el-input>
        </el-form-item>
        <el-form-item label="资金下达（元）">
          <el-input v-model="form.release_money" disabled></el-input>
        </el-form-item>
        <el-form-item label="验收确认（元）">
          <el-input v-model="form.check_money" disabled></el-input>
        </el-form-item>

        <h1>收款信息</h1>
        <el-table :data="form.collection" style="width: 100%" :key="Math.random()">
          <el-table-column width="50">
            <template slot="header" slot-scope="scope">
              <button @click="addLine" class="addBtn">
                <i class="addLine"></i>
              </button>
            </template>
            <template slot-scope="scope">
              <button
                v-if="form.collection.length > 0"
                @click="handleDelete(scope.$index, scope.row)"
                class="del-btn"
              >
                <i class="delLine"></i>
              </button>
            </template>
          </el-table-column>
          <el-table-column label="批次">
            <template slot-scope="scope">
              <el-input v-model="scope.row.batch"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="收款金额">
            <template slot-scope="scope">
              <el-input v-model="scope.row.money"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="收款时间">
            <template slot-scope="scope">
              <el-date-picker v-model="scope.row.collection_time" type="date" placeholder="选择日期"></el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="scope">
              <el-input v-model="scope.row.remark"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit" :disabled="isDisable">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,
      labelPosition: "top",
      isDisable: false,
      form: {
        company_name: "",
        contract_name: "",
        product_name: "",
        signatory: "",
        down_time: "",
        receivables: "",
        received: "",
        check: "",
        refund: "",
        billing_method: "",
        total_money: "",
        deposit: "",
        submit_money: "",
        pass_money: "",
        release_money: "",
        check_money: "",
        collection: [],
      },
      formRule: {
        receivables: [
          {
            required: true,
            message: "请输入应收款",
            trigger: "blur",
          },
        ],
        received: [
          {
            required: true,
            message: "请输入已收款",
            trigger: "blur",
          },
        ],
        uncollected: [
          {
            required: true,
            message: "请输入待收款",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/finance/view", {
          params: {
            contract_project_id: this.meber_id,
          },
        })
        .then((res) => {
          console.log(res);
          this.form = res.data;
          // this.form.collection = this.form.collection ? this.form.collection : []
        });
    }
  },
  methods: {
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        this.form.id;
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/finance/store", this.form)
            .then((res) => {
              console.log(res);
              this.$router.go(-1);
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.btnLoading = false
              this.isDisable = true;
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.error("信息未完善");
        }
      });
    },
    addLine () {
      //添加行数
      var newValue = {};
      this.form.collection.push(newValue);
      console.log(this.form.collection);
    },
    handleDelete (index) {
      //删除行数
      this.form.collection.splice(index, 1);
    },
  },
};
</script>

<style scoped="scoped">
::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}
.el-table td.el-table__cell div {
  width: 100%;
}
</style>
